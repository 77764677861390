import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    message: String
  }

  showAlert({ params }) {
    const alertContent = params.message || this.messageValue
    
    if (alertContent) {
      alert(alertContent)
    }
  }
}
